import React from "react"
import { graphql } from "gatsby"
import { PageLayout, PageTitle, WorkHistory } from "../components"
import { SEO, Utils } from "../utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Container from "react-bootstrap/Container"

export default ({ data }) => {
  
  return (
    <PageLayout>
      <SEO title="Resume" />
      <PageTitle title="Resume">
      <div >
        <hr></hr>
      <iframe src="https://drive.google.com/file/d/1-IvsT91ObUfH1IjRUN6wYqa2cR9_SnkD/preview" width="75%" height="1000"></iframe>
      </div>
      </PageTitle>
      
    </PageLayout>
  )
}

